import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border rounded m-2 px-2 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vxe_checkbox = _resolveComponent("vxe-checkbox")!
  const _component_vxe_checkbox_group = _resolveComponent("vxe-checkbox-group")!
  const _component_vxe_form_item = _resolveComponent("vxe-form-item")!
  const _component_vxe_button = _resolveComponent("vxe-button")!
  const _component_vxe_form = _resolveComponent("vxe-form")!

  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createVNode(_component_vxe_form, {
      class: "",
      ref: "anyForm",
      data: _ctx.table.formData,
      rules: _ctx.table.rules,
      titleAlign: "right",
      onSubmit: _ctx.table.save
    }, {
      default: _withCtx(() => [
        _createVNode(_component_vxe_form_item, {
          title: "匯出欄位",
          field: "Selected",
          "item-render": {},
          span: "24"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_checkbox_group, {
              modelValue: data.Selected,
              "onUpdate:modelValue": ($event: any) => ((data.Selected) = $event)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "1",
                  content: "會員編號"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "2",
                  content: "公司"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "3",
                  content: "姓名"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "4",
                  content: "性別"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "5",
                  content: "部門"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "6",
                  content: "職稱"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "7",
                  content: "帳號(Email)"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "8",
                  content: "上市櫃"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "9",
                  content: "行業別"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "10",
                  content: "通訊地址"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "11",
                  content: "如何得知MII金屬情報網"
                }),
                _createVNode(_component_vxe_checkbox, {
                  class: "m-1",
                  label: "12",
                  content: "參與的活動"
                })
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          "item-render": {},
          span: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_vxe_button, {
              type: "buttom",
              onClick: _ctx.table.selectedAll,
              status: "primary",
              content: "全選"
            }, null, 8, ["onClick"]),
            _createVNode(_component_vxe_button, {
              type: "submit",
              status: "primary",
              content: "匯出"
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "rules", "onSubmit"])
  ]))
}