import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43c70458"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-2 px-2 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vxe_input = _resolveComponent("vxe-input")!
  const _component_vxe_form_item = _resolveComponent("vxe-form-item")!
  const _component_vxe_option = _resolveComponent("vxe-option")!
  const _component_vxe_select = _resolveComponent("vxe-select")!
  const _component_vxe_button = _resolveComponent("vxe-button")!
  const _component_vxe_form = _resolveComponent("vxe-form")!

  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createVNode(_component_vxe_form, {
      class: "",
      ref: "form",
      data: _ctx.table.formData,
      rules: _ctx.table.rules,
      titleAlign: "right",
      onSubmit: _ctx.table.save
    }, {
      default: _withCtx(() => [
        _createVNode(_component_vxe_form_item, {
          title: "ID",
          field: "Id",
          "item-render": {},
          span: "12"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_input, {
              modelValue: data.Id,
              "onUpdate:modelValue": ($event: any) => ((data.Id) = $event),
              type: "text",
              placeholder: "",
              disabled: "",
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          title: "支付方式",
          field: "Type",
          "item-render": {},
          span: "12"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_select, {
              modelValue: data.Type,
              "onUpdate:modelValue": ($event: any) => ((data.Type) = $event),
              placeholder: "請選擇支付方式",
              clearable: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.model?.enums?.PaymentType), (item) => {
                  return (_openBlock(), _createBlock(_component_vxe_option, {
                    key: item.Value,
                    value: item.Value,
                    label: item.Name
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          title: "繳費時間",
          field: "Time",
          "item-render": {},
          span: "12"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_input, {
              modelValue: data.Time,
              "onUpdate:modelValue": ($event: any) => ((data.Time) = $event),
              type: "date",
              labelFormat: "yyyy/MM/dd HH:mm:ss",
              placeholder: "請輸入日期",
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          title: "繳費期限",
          field: "ExpriyTime",
          "item-render": {},
          span: "12"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_input, {
              modelValue: data.ExpriyTime,
              "onUpdate:modelValue": ($event: any) => ((data.ExpriyTime) = $event),
              type: "date",
              labelFormat: "yyyy/MM/dd HH:mm:ss",
              placeholder: "請輸入日期",
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          title: "交易日期",
          field: "TransactionDate",
          "item-render": {},
          span: "12"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_input, {
              modelValue: data.TransactionDate,
              "onUpdate:modelValue": ($event: any) => ((data.TransactionDate) = $event),
              type: "date",
              labelFormat: "yyyy/MM/dd",
              placeholder: "請輸入日期",
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          title: "卡/帳號",
          field: "Pan",
          "item-render": {},
          span: "12"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_input, {
              modelValue: data.Pan,
              "onUpdate:modelValue": ($event: any) => ((data.Pan) = $event),
              type: "text",
              placeholder: "請輸入文字",
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          title: "金額",
          field: "Amount",
          "item-render": {},
          span: "12"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_input, {
              modelValue: data.Amount,
              "onUpdate:modelValue": ($event: any) => ((data.Amount) = $event),
              type: "number",
              placeholder: "請輸入數字",
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          title: "狀態",
          field: "Status",
          "item-render": {},
          span: "12"
        }, {
          default: _withCtx(({ data }) => [
            _createVNode(_component_vxe_select, {
              modelValue: data.Status,
              "onUpdate:modelValue": ($event: any) => ((data.Status) = $event),
              placeholder: "請選擇支付狀態",
              clearable: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.model?.enums?.PaymentStatus).filter(e => e.Value == 0 || e.Value == 11 ||e.Value == 24), (item) => {
                  return (_openBlock(), _createBlock(_component_vxe_option, {
                    key: item.Value,
                    value: item.Value,
                    label: item.Name
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_vxe_form_item, {
          "class-name": "text-left",
          "item-render": {},
          span: "24"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_vxe_button, {
              class: "",
              type: "submit",
              status: "primary",
              content: "確認"
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "rules", "onSubmit"])
  ]))
}