
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    visible: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Type', order: 'asc' }, { field: "Level", order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      save() {
        emit('save', table.formData.Selected)
        // if (table.formData.Type != null && table.formData.CopyType != null && table.formData.Type >= 0 && table.formData.CopyType >= 0) {
        //   emit('save', { type: table.formData.Type, copyType: table.formData.CopyType });
        // }
      },
      selectedAll() {
        table.formData.Selected = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12' ]
      },
      rules: {
        Selected: [{ required: true }],
      }
    })

    watch(() => props.visible, async(value) => {
      if(value) {
        table.formData = {} as any
      }      
    }, { immediate: true });

    const anyForm = ref<any>();

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      model,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
